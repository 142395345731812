import styles from './competences.module.scss';
import Link from 'next/link';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useMemo } from 'react';
import Output from 'editorjs-react-renderer';
import { useTranslation } from 'next-i18next';

const config = {
  header: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
};

const Competences = ({ pageData }) => {
  const { t } = useTranslation(['common', 'main']);
  const competencesBlock = useMemo(
    () => pageData.blocks.find(block => block.block_name === 'home_competences'),
    [pageData.id]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.num}>03</div>
        <motion.header
          className={styles.header}
          initial={{
            overflow: 'hidden',
            width: '0%',
            whiteSpace: 'nowrap',
          }}
          animate={{
            width: '100%',
            transition: { duration: 2.2, delay: 0.6 },
          }}
          exit={{
            opacity: 0,
            y: '-15%',
            transition: { duration: 0.6 },
          }}
        >
          {competencesBlock?.title || t('КЛЮЧЕВЫЕ КОМПЕТЕНЦИИ')}
        </motion.header>
        <motion.div
          className={styles.title}
          initial={{
            opacity: 0,
            y: '50%',
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 1.1, delay: 1.2 },
          }}
          exit={{
            opacity: 0,
            y: '-15%',
            transition: { duration: 0.6 },
          }}
        >
          {competencesBlock?.short_title ?? t('Отлаженный процесс логистики')}
        </motion.div>
        <motion.div
          className={clsx(styles.desc, styles.hiddenXs)}
          initial={{
            opacity: 0,
            y: '25%',
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 1.1, delay: 1.5 },
          }}
          exit={{
            opacity: 0,
            y: '-15%',
            transition: { duration: 0.6 },
          }}
        >
          {competencesBlock?.text && competencesBlock?.text?.blocks.length > 0 ? (
            <Output data={competencesBlock.text} config={config} />
          ) : (
            t(`Мы следим за каждым этапом поставки продукции, работая по международным стандартам
          качества, чтобы наши клиенты получали лучший продукт. Выверенный алгоритм работы
          подразумевает 4 основных этапа.`)
          )}
        </motion.div>
        <div className={clsx(styles.readMore, styles.hiddenXs)}>
          <Link href="/about">
            <a>
              <motion.span
                initial={{
                  opacity: 0,
                  x: '-24px',
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.1, delay: 1.8 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('читать далее')}
              </motion.span>
              <motion.span
                className={styles.arrow}
                initial={{
                  opacity: 0,
                  x: '16px',
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.1, delay: 2 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              />
            </a>
          </Link>
        </div>
      </div>
      <div className={styles.right}>
        {competencesBlock?.listing && competencesBlock.listing.length > 0 ? (
          competencesBlock.listing.map(l => (
            <div key={l.id} className={styles.item}>
              <motion.div
                className={styles.subHeader}
                initial={{
                  overflow: 'hidden',
                  width: '0%',
                  whiteSpace: 'nowrap',
                }}
                animate={{
                  width: '100%',
                  transition: { duration: 2.2, delay: 0.6 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {l.title}
              </motion.div>
              <motion.p
                initial={{
                  opacity: 0,
                  y: '25%',
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.1, delay: 1.5 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {l.description}
              </motion.p>
            </div>
          ))
        ) : (
          <>
            <div className={styles.item}>
              <motion.div
                className={styles.subHeader}
                initial={{
                  overflow: 'hidden',
                  width: '0%',
                  whiteSpace: 'nowrap',
                }}
                animate={{
                  width: '100%',
                  transition: { duration: 2.2, delay: 0.6 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('Закупка')}
              </motion.div>
              <motion.p
                initial={{
                  opacity: 0,
                  y: '25%',
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.1, delay: 1.5 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('Широкая и эффективная сеть взаимодействия с зарубежными поставщиками')}
              </motion.p>
            </div>
            <div className={styles.item}>
              <motion.div
                className={styles.subHeader}
                initial={{
                  overflow: 'hidden',
                  width: '0%',
                  whiteSpace: 'nowrap',
                }}
                animate={{
                  width: '100%',
                  transition: { duration: 2.2, delay: 0.6 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('ПЕРЕВОЗКА')}
              </motion.div>
              <motion.p
                initial={{
                  opacity: 0,
                  y: '25%',
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.1, delay: 1.5 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('Надежная и быстрая транспортировка груза')}
              </motion.p>
            </div>
            <div className={styles.item}>
              <motion.div
                className={styles.subHeader}
                initial={{
                  overflow: 'hidden',
                  width: '0%',
                  whiteSpace: 'nowrap',
                }}
                animate={{
                  width: '100%',
                  transition: { duration: 2.2, delay: 0.6 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('ПРИЕМКА')}
              </motion.div>
              <motion.p
                initial={{
                  opacity: 0,
                  y: '25%',
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.1, delay: 1.5 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('Контроль соответствия товара, проверка продукта на термоленте')}
              </motion.p>
            </div>
            <div className={styles.item}>
              <motion.div
                className={styles.subHeader}
                initial={{
                  overflow: 'hidden',
                  width: '0%',
                  whiteSpace: 'nowrap',
                }}
                animate={{
                  width: '100%',
                  transition: { duration: 2.2, delay: 0.6 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('ХРАНЕНИЕ')}
              </motion.div>
              <motion.p
                initial={{
                  opacity: 0,
                  y: '25%',
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.1, delay: 1.5 },
                }}
                exit={{
                  opacity: 0,
                  y: '-15%',
                  transition: { duration: 0.6 },
                }}
              >
                {t('Передовые технологии охлаждения и складирования')}
              </motion.p>
            </div>
          </>
        )}
      </div>
      <motion.div
        className={clsx(styles.desc, styles.visibleXs)}
        initial={{
          opacity: 0,
          y: '25%',
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 1.1, delay: 1.5 },
        }}
        exit={{
          opacity: 0,
          y: '-15%',
          transition: { duration: 0.6 },
        }}
      >
        {competencesBlock?.text && competencesBlock?.text?.blocks.length > 0 ? (
          <Output data={competencesBlock.text} config={config} />
        ) : (
          t(`Мы следим за каждым этапом поставки продукции, работая по международным стандартам
          качества, чтобы наши клиенты получали лучший продукт. Выверенный алгоритм работы
          подразумевает 4 основных этапа.`)
        )}
      </motion.div>
      <div className={clsx(styles.readMore, styles.visibleXs)}>
        <Link href="/about">
          <a>
            <motion.span
              initial={{
                opacity: 0,
                x: '-24px',
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 1.1, delay: 2 },
              }}
              exit={{
                opacity: 0,
                y: '-15%',
                transition: { duration: 0.6 },
              }}
            >
              {t('читать далее')}
            </motion.span>
            <motion.span
              className={styles.arrow}
              initial={{
                opacity: 0,
                x: '16px',
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 1.1, delay: 2 },
              }}
              exit={{
                opacity: 0,
                y: '-15%',
                transition: { duration: 0.6 },
              }}
            />
          </a>
        </Link>
      </div>
    </div>
  );
};

export default Competences;
