import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useKeenSlider } from 'keen-slider/react';
import Link from 'next/link';
import Router from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dorado_img from './dorado.png?url';
import langoustines_img from './langoustines.png?url';
import mussels_img from './mussels.png?url';
import styles from './production.module.scss';
import Output from 'editorjs-react-renderer';
import { useTranslation } from 'next-i18next';

const config = {
  header: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
};

const Production = ({ pageData }) => {
  const { t } = useTranslation(['common', 'main']);
  const [details, setDetails] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    duration: 5000,
    controls: false,

    move(s) {
      setDetails(s.details());
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const images = [
    { id: 1, name: t('Дорадо'), image: dorado_img },
    { id: 2, name: t('Лангустины'), image: langoustines_img },
    { id: 3, name: t('Мидии'), image: mussels_img },
  ];

  const getSrcSet = useCallback(img => {
    return Object.entries(img.thumbnails)
      .sort(([key]) => -key)
      .reduce(
        (acc, curr) => (!!acc.length ? `${curr[1]} ${curr[0]}w, ${acc}` : `${curr[1]} ${curr[0]}w`),
        ''
      );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => slider.next(), 7000);
    return () => clearInterval(interval);
  }, [slider]);

  // TODO: hurrying is bad.. gotta fix this shit
  const positionStyle = useCallback(
    idx => {
      if (!details) return {};
      return {
        opacity: details.positions[idx].portion,
        zIndex: '-1',
      };
    },
    [details?.positions]
  );

  const productionBlock = useMemo(
    () => pageData.blocks.find(block => block.block_name === 'home_production'),
    [pageData.id]
  );

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.left} style={{ backgroundImage: `url(${product_img})` }} /> */}

      <div className={styles.right}>
        <div className={styles.head}>
          <motion.header
            className={styles.header}
            initial={{
              overflow: 'hidden',
              width: '0%',
              whiteSpace: 'nowrap',
            }}
            animate={{
              width: '100%',
              transition: { duration: 2.3, delay: 0.6 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            {productionBlock?.title || t('Продукция')}
          </motion.header>
          <motion.div
            className={styles.num}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.1,
              transition: { duration: 2.3 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            02
          </motion.div>
          <motion.div
            className={styles.title}
            initial={{
              opacity: 0,
              y: '50%',
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.1, delay: 1.2 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            {productionBlock?.short_title || t('Широкий ассортимент')}
          </motion.div>
        </div>
        <motion.div
          className={styles.slider}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 2.3, delay: 0.8 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.6 },
          }}
        >
          <div ref={sliderRef} className="keen-slider">
            {productionBlock?.products && productionBlock.products.length > 0
              ? productionBlock.products.map((slide, idx) => {
                  if (slide.image[0]) {
                    return (
                      <div
                        key={slide.id}
                        className="keen-slider__slide"
                        style={{ ...positionStyle(idx), zIndex: 1 }}
                      >
                        <div className={styles.slide}>
                          <img
                            style={{ cursor: 'pointer' }}
                            src={slide.image[0].image}
                            alt={slide.title}
                            srcSet={getSrcSet(slide.image[0])}
                            sizes="50vw"
                            onClick={() => Router.push(`/catalog/product/${slide.id}`)}
                          />
                        </div>
                      </div>
                    );
                  }
                })
              : images.map((slide, idx) => (
                  <div key={slide.id} className="keen-slider__slide" style={positionStyle(idx)}>
                    <div className={styles.slide}>
                      <img
                        src={slide.image}
                        alt={slide.name}
                        srcSet={getSrcSet(slide)}
                        sizes="50vw"
                      />
                    </div>
                  </div>
                ))}
          </div>
          {slider && (
            <div className={styles.dots}>
              {[...Array(slider.details().size).keys()].map((idx, k) => {
                const delay = k * 0.2 + 1;
                return (
                  <motion.div
                    key={idx}
                    onClick={() => {
                      slider.moveToSlideRelative(idx);
                    }}
                    className={clsx(styles.dot, { [styles.dotActive]: currentSlide === idx })}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.8, delay },
                    }}
                  />
                );
              })}
            </div>
          )}
        </motion.div>
        <div className={styles.bottom}>
          <motion.div
            className={styles.desc}
            initial={{
              opacity: 0,
              y: '25%',
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.1, delay: 1.5 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            {productionBlock?.text && productionBlock?.text?.blocks.length > 0 ? (
              <Output data={productionBlock.text} config={config} />
            ) : (
              t(
                'В списке продукции нашей компании вы всегда сможете найти популярные сорта рыбы, такие как лосось, форель, сельдь, хек. А также открыть экзотические деликатесы: креветка ванамей, лангустины, филе гигантского кальмара.'
              )
            )}
          </motion.div>
          <div className={styles.readMore}>
            <Link href="/catalog">
              <a>
                <motion.span
                  initial={{
                    opacity: 0,
                    x: '-24px',
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.1, delay: 2 },
                  }}
                  exit={{
                    opacity: 0,
                    y: '-15%',
                    transition: { duration: 0.6 },
                  }}
                >
                  { t('в каталог продукции') }
                </motion.span>
                <motion.span
                  className={styles.arrow}
                  initial={{
                    opacity: 0,
                    x: '16px',
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.1, delay: 1.8 },
                  }}
                  exit={{
                    opacity: 0,
                    y: '-15%',
                    transition: { duration: 0.6 },
                  }}
                />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
