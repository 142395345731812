import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styles from './intro.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

let easing = [0.175, 0.85, 0.42, 0.96];
const backVariants = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  initial: {
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: easing,
    },
  },
  enter: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 10,
      ease: easing,
    },
  },
};

const Intro = ({ mobile = false, index, setIndex }) => {
  const { t } = useTranslation(['common', 'main']);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    if (!mobile) {
      setShowVideo(true);
    }
  }, []);
  return (
    <div className={clsx(styles.wrapper, { [styles.mobile]: mobile })}>
      {!mobile && !showVideo && <div className={styles.desktopBg} />}
      {showVideo && (
        <motion.video
          className={styles.video}
          playsInline
          autoPlay
          muted
          loop
          poster="/static/video/bg-1080.jpg"
          initial="initial"
          animate="enter"
          exit="exit"
          variants={backVariants}
        >
          <source src="/static/video/bg-1080.av1.mp4" type="video/mp4; codecs=av01,opus" />
          <source src="/static/video/bg-1080.mp4" type="video/mp4" />
          <source src="/static/video/bg-1080.webm" type="video/webm" />
          {/*<source src="/static/video/bg-1080.ogv" type="video/ogg" />*/}
        </motion.video>
      )}
      {mobile && <div className={styles.mobileBg} />}
      {!mobile && <div className={styles.gradients} />}
      <motion.div
        className={styles.inner}
        exit={{
          opacity: 0,
          y: '-15%',
          transition: { duration: 0.5 },
        }}
      >
        <motion.header
          className={styles.header}
          initial={{
            overflow: 'hidden',
            width: '0%',
            whiteSpace: 'nowrap',
          }}
          animate={{
            width: '100%',
            transition: { duration: 2.3, delay: 0.7 },
          }}
        >
          {t('Путеводитель')}
        </motion.header>
        <h1>
          <span className={styles.titleLine}>
            <motion.span
              initial={{ opacity: 0, y: '100%' }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 1, delay: 1 },
              }}
            >
              {t('Создано морем')}
            </motion.span>
          </span>
          <span className={styles.titleLine}>
            <motion.span
              initial={{ opacity: 0, y: '100%' }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 1, delay: 1.2 },
              }}
            >
              {t('Доставлено ЮНИФРОСТ')}
            </motion.span>
          </span>
        </h1>
        <div className={styles.desc}>
          <div className={styles.line}>
            <motion.span
              className={styles.animatedLine}
              initial={{ opacity: 0, width: '0%' }}
              animate={{
                opacity: 1,
                width: '100%',
                transition: { duration: 0.7, delay: 1.6 },
              }}
            />
          </div>
          <motion.span
            initial={{ opacity: 0, y: '50%' }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.8, delay: 1.8 },
            }}
          >
            {t(
              'Комплексные решения по перевозке, хранению и оптовой продаже замороженной рыбной продукции и морепродуктов'
            )}
          </motion.span>
        </div>
        {!mobile && (
          <motion.div
            onClick={() => setIndex(index + 1)}
            className={styles.scrollDown}
            initial={{ opacity: 0, y: '-16px' }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.8, delay: 2.2 },
            }}
          >
            {t('Далее')}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default Intro;
