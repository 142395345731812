import Footer from '@/components/Footer';
import { motion } from 'framer-motion';
import styles from './partners.module.scss';
import useWindowDimensions from '@/utils/windowDimensions';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';
import { SettingsContext } from '@/utils/SettingsContext';
import { useTranslation } from 'next-i18next';

const Partners = ({ slide, pageData = { partners: [] } }) => {
  const { t } = useTranslation(['common', 'main']);
  const { width } = useWindowDimensions();
  const settings = useContext(SettingsContext);

  const partnersChunks = useMemo(() => {
    let chunkSize = 5;
    if (width < 478) {
      chunkSize = 1;
    } else if (width < 960) {
      chunkSize = 3;
    }

    return pageData.partners.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  }, [width, pageData.partners]);

  const [details, setDetails] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    duration: 5000,
    controls: false,
    move(s) {
      setDetails(s.details());
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => slider.next(), 7000);
    return () => clearInterval(interval);
  }, [slider]);

  const positionStyle = useCallback(
    idx => {
      if (!details) return {};
      return {
        opacity: details.positions[idx].portion,
        zIndex: '-1',
      };
    },
    [details?.positions]
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <motion.header
          className={styles.header}
          initial={{
            overflow: 'hidden',
            width: '0%',
            whiteSpace: 'nowrap',
          }}
          animate={{
            width: '100%',
            transition: { duration: 2.3, delay: 0.6 },
          }}
          exit={{
            opacity: 0,
            y: '-15%',
            transition: { duration: 0.6 },
          }}
        >
          {t('Партнёры')}
        </motion.header>
        <motion.div
          className={styles.partners}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 1.2, delay: 0.6 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.6 },
          }}
        >
          <div className={styles.body}>
            <div ref={sliderRef} className={`keen-slider ${styles.slider}`}>
              {partnersChunks.map((partners, idx) => (
                <div
                  key={idx}
                  className={`keen-slider__slide ${styles.slide}`}
                  style={{ ...positionStyle(idx), zIndex: 1 }}
                >
                  {partners.map(partner => (
                    <div key={partner.id}>
                      <img src={partner.image} alt={partner.title} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {false && (
              <div className={styles.dots}>
                {[...Array(slider.details().size).keys()].map((idx, k) => {
                  const delay = k * 0.2 + 1;
                  return (
                    <motion.div
                      key={idx}
                      onClick={() => {
                        slider.moveToSlideRelative(idx);
                      }}
                      className={clsx(styles.dot, { [styles.dotActive]: currentSlide === idx })}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 0.8, delay },
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </motion.div>
      </div>
      <Footer
        instagram_visible={settings?.INSTAGRAM_LINK_VISIBLE}
        instagram={settings?.INSTAGRAM_LINK}
      />
    </div>
  );
};

export default Partners;
