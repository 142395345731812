import styles from './geo.module.scss';
import Link from 'next/link';
import data from '@/components/Geo/map.json';
import DottedMap from '@/components/Geo/DottedMap.jsx';
import { motion } from 'framer-motion';
import { useState, useMemo } from 'react';
import Output from 'editorjs-react-renderer';
import { useTranslation } from 'next-i18next';

const config = {
  header: {
    disableDefaultStyle: true,
  },
  paragraph: {
    disableDefaultStyle: true,
  },
  image: {
    disableDefaultStyle: true,
  },
  list: {
    disableDefaultStyle: true,
  },
};

const Geo = ({ slide, pageData = { map: [] } }) => {
  const [countries, setCountries] = useState(pageData.map);
  const { t } = useTranslation(['common', 'main']);

  const geoBlock = useMemo(
    () => pageData.blocks.find(block => block.block_name === 'home_geo'),
    [pageData.id]
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <motion.div
            className={styles.num}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.1,
              transition: { duration: 2.3 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            01
          </motion.div>
          <motion.header
            className={styles.header}
            initial={{
              overflow: 'hidden',
              width: '0%',
              whiteSpace: 'nowrap',
            }}
            animate={{
              width: '100%',
              transition: { duration: 2.3, delay: 0.5 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.5 },
            }}
          >
            <span>{geoBlock?.title || t('География закупок')}</span>
          </motion.header>
          <motion.div
            className={styles.title}
            initial={{
              opacity: 0,
              y: '50%',
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.1, delay: 1.2 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.5 },
            }}
          >
            {geoBlock?.short_title || t('Рыба со всего мира')}
          </motion.div>
          <motion.div
            className={styles.map}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: { duration: 2.3, delay: 0.8 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.5 },
            }}
          >
            <DottedMap simple={true} data={data} pins={countries} />
          </motion.div>
          <motion.div
            className={styles.desc}
            initial={{
              opacity: 0,
              y: '25%',
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1, delay: 1.5 },
            }}
            exit={{
              opacity: 0,
              y: '-15%',
              transition: { duration: 0.6 },
            }}
          >
            {geoBlock?.text && geoBlock?.text?.blocks.length > 0 ? (
              <Output data={geoBlock.text} config={config} />
            ) : (
              <>
                {t('Юнифросsт имеет широкую географию закупок.')}
                <br />{' '}
                {t('Наши контрагенты предоставляют рыбу и морепродукты из разных уголков планеты.')}
                {t('Продукты с Фарерских островов, Индии, Перу, Чили, Аргентины и других стран.')}
              </>
            )}
          </motion.div>
          <div className={styles.readMore}>
            <Link href="/geo">
              <a>
                <motion.span
                  initial={{
                    opacity: 0,
                    x: '-24px',
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.1, delay: 1.8 },
                  }}
                  exit={{
                    opacity: 0,
                    y: '-15%',
                    transition: { duration: 0.6 },
                  }}
                >
                  {t('читать далее')}
                </motion.span>
                <motion.span
                  className={styles.arrow}
                  initial={{
                    opacity: 0,
                    x: '16px',
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.1, delay: 1.8 },
                  }}
                  exit={{
                    opacity: 0,
                    y: '-15%',
                    transition: { duration: 0.6 },
                  }}
                />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Geo;
